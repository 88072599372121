import '../css/home_new.scss'; // Unikalne style

// import '../css/school.scss'; // Szablonowe style

import {liveLoadData, filterLoad, submitData, dataFromForm, getData} from './lib/livedata';
import {strToArray, scroll_to} from './lib/helpers';
import {getServerUrl} from './lib/url';
import {minDays, shortDays, longDays, shortMonths, longMonths, dateMidFormat, dateMidScheme} from './lib/locales';
import {createCookie, checkCookie, readCookie} from './lib/cookie';
import simpleParallax from 'simple-parallax-js';

$(document).ready(function () {

    var $box = $('main'),
        $body = $('body'),
        $searchForm = $box.find('.search-form-academies');

    $(document.body).on('click', '#replyy', function (e) {
        console.log("dsa");
        $('#replyy').show();
    });

    $(document.body).on('click', '#reply_comment', function (e) {
        console.log("asd");
        $('#replyy').hide();
    });
    //////// BUTTONY ADMINA ///////

    $box.on('click', '.btn-admin-get-action', function () {
        var $btn = $(this);
        $btn.blur();
        var action = $btn.data('action'),
            fill = $btn.data('fill') === undefined ? null : $btn.data('fill'),
            typeFill = $btn.data('typeFill') === undefined ? 'html' : $btn.data('typeFill'),
            $formFind = $btn.data('formFind') === undefined ? null : $box.find($btn.data('formFind')),
            $fillFind = $btn.data('fillFind') === undefined ? null : $box.find($btn.data('fillFind'));
        getData(action, fill, typeFill, $formFind, $fillFind);
    });

    $(document).on('keyup', '.search-phrase-academies', function (event) {
        if (event.which !== 13) {
            var $list = $(this).closest('form').find('.academies-accordion-list');

            var $fill = $list,
                szukam = $(this).val(),
                $searchPhrase = $searchForm.find('input[name="phrase"]');
            $searchPhrase.val(szukam);
            submitData(dataFromForm($searchForm), $searchForm.prop('action'), $fill);
            if (szukam.length > 0 && $fill.hasClass('d-none')) {
                $fill.removeClass('d-none');
            } else if (szukam.length < 1 && !$fill.hasClass('d-none')) {
                $fill.addClass('d-none');
            }
        }
    });

    $('main').on('click', '#scrollToOffer', function (e) {
        e.preventDefault();
        scroll_to('.main-bottom', 800);
    });

    $(document.body).on('click', '#scrollToBlog', function (e) {
        e.preventDefault();
        scroll_to('.main-additional ', 800);
    });
	
    ////////// DODANIE PROFILU /////////

    // kopiowanie nazwy do skróconej nazwy
    $body.on('focusout', '#newAcademyName', function () {
        var $name = $(this),
            $short = $name.closest('form').find("#newAcademyShortName");
        if (!$short.val().length && $name.val().length) {
            $short.val($name.val());
        }
    });

    ////////// LIGHTBOX //////////

    // otwieranie okna modalnego w bloku all-list
    $box.on('click', '.all-list .open-modal', function () {
        var $btn = $(this),
            modalW = $btn.closest('.all-list').data('modal'),
            $modalW = $('main').find(modalW),
            nrSlide = $btn.data('slide');
        console.log('nrSlide', nrSlide);
        currentSlide(nrSlide, $modalW);
        openModal($modalW);
    });

    // modalne okno modal-all-list
    $box.on('click', '.modal-all-list .close', function () {
        closeModal($(this).closest('.modal-all-list'));
    });
    $box.on('click', '.modal-all-list .prev', function () {
        var $modalW = $(this).closest('.modal-all-list');
        plusSlides(-1, $modalW);
    });
    $box.on('click', '.modal-all-list .next', function () {
        var $modalW = $(this).closest('.modal-all-list');
        plusSlides(1, $modalW);
    });
    $box.on('click', '.modal-all-list .slide-thumb', function () {
        var $btn = $(this),
            $modalW = $btn.closest('.modal-all-list'),
            nrSlide = $btn.data('slide');
        currentSlide(nrSlide, $modalW);
    });



    ////////// Ostatnie czynności po załadowniu szkieletu strony /////////

    // aktywacja tooltips (dymków)
    $('[data-toggle*="tooltip"]').tooltip();

    // Switch uruchomienia
    $('.custom-switch input').on('change', function(){
        var parent = $(this).parent();
        if($(this).is(':checked'))
            parent.addClass('style-1');
        else
            parent.removeClass('style-1');
    });
	
	/////////// załadowanie events, news i gallery ////////////

	var url = $('body').data('siteUrl'),
		$fillEvents = $('#startEvents'),
		$fillNews = $('#startNews');

	getData(url + 'events/start/home', $fillEvents);
    getData(url + 'news/data/home/0/start', $fillNews);

    const loader = document.querySelectorAll("[data-load]");
    loader.forEach(function(load) {
        load.dataset.load = load.dataset.load === "loading" ? "loaded" : "loading";
    });

    /////////// resize events, news i gallery ////////////
    $(window).on('resizeEnd', function(){
        getData(url + 'events/start/home', $fillEvents);
        getData(url + 'news/data/home/0/start', $fillNews);
    });

});

////// modal lightbox ///////
function openModal($mod) {
    $mod.show(400);
}

function closeModal($mod) {
    $mod.hide(400);
}

var slideIndex = 1;

// Next/previous controls
function plusSlides(n, $mod) {
    showSlides(slideIndex += n, $mod);
}

// Thumbnail image controls
function currentSlide(n, $mod) {
    showSlides(slideIndex = n, $mod);
}

function showSlides(n, $mod) {
    var i,
        slides = $mod.find(".slide-image"),
        dots = $mod.find(".slide-thumb");
    if (n > slides.length) {
        slideIndex = 1;
    } else if (n < 1) {
        slideIndex = slides.length;
    } else {
        slideIndex = n;
    }
    for (i = 0; i < slides.length; i++) {
        slides[i].style.display = "none";
    }
    for (i = 0; i < dots.length; i++) {
        dots[i].className = dots[i].className.replace(" active", "");
    }
    slides[slideIndex - 1].style.display = "block";
    dots[slideIndex - 1].className += " active";
}

/////// modal lightbox end ///////


function searchToCookie($form) {
    var data = dataFromForm($form);
    console.log('dataFromForm', data);
    createCookie('search', data);
    console.log('nevCookie', readCookie('search'));
}

// kiedy się uruchamia strona
function cookieSearchToSearchForm($form) {
    if (checkCookie('search')) {
        console.log('check tak', checkCookie('search'));
        var data = readCookie('search');
    } else {
        console.log('check nie', checkCookie('search'));
        var data = dataFromForm($form);
        createCookie('search', data);
    }
    console.log('data', data);
//	$.each(data, function(){
//		console.log(this);
//	});
//	data.each(function(idx){
//		console.log(idx, this);
//		$('#liveDataLoad').before('<div>'+idx+'. '+this.name+': '+this.value+'</div>');
//	});
}

// zapis w oknie modalnym
// $blockForm - widok z formularzem do włożenia na stronę
//function formSend($form, action, fill) {
//	var goodData = true;
//	// sprawdzenie wymaganych inputów i checkboxów, czy mają wartość
//	$form.find('input').each(function () {
//		var $inp = $(this);
//		if ($inp.prop('required') && (
//				($inp.prop('type') === 'checkbox' && !$inp.isChecked)
//				||
//				($inp.prop('type') !== 'checkbox' && !$inp.val().trim().length)
//				)) {
//			$inp.addClass('error');
//			goodData = false;
//		} else if ($inp.hasClass('error')) {
//			$inp.removeClass('error');
//		}
//	});
//	// sprawdzenie wymaganych selectów, które mają ID jako wartość
//	$form.find('select').each(function () {
//		var $sel = $(this);
//		if ($sel.prop('required') && !parseInt($sel.val(), 10)) {
//			$sel.addClass('error');
//			goodData = false;
//		} else if ($sel.hasClass('error')) {
//			$sel.removeClass('error');
//		}
//	});
//	// jeśli nie wszystkie wymagane pola są wypełnione, kończymy
//	if (!goodData) {
//		return false;
//	}
// zbieramy dane z aktualnego formularza (osoba lub zbiórka)
//	var data = dataFromForm($form);
//	// wysyłamy dane do kontrolera php wskazując, co ma podmienić nowy widok
//	if ($form.prop('id') === 'formCollection') {
//		// jeżeli dodajemy zbiórkę, niech się doda blok zbiórki do listy zbiórek
//		var $box = $('main'),
//				$fillContent = $box.find('#liveDataLoad'),
//				$formFind = $fillContent.find('#searchForm');
//			submitData(data, action, fill, 'html', $formFind, $fillContent);
//	} else {
//		// jeśli to osoba, to aktualizujemy tylko sam formularz w oknie modalnym
//		submitData(data, action, fill);
//	}
//};

// slider-range (nieużywane tu)
function sliderRange(amountMin, amountMax) {
    var $box = $('main');
    $box.find('#slider-range').slider({
        range: true,
        min: amountMin,
        max: amountMax,
        step: 10000,
        values: [amountMin, amountMax],
        slide: function (event, ui) {
            $box.find('#amount-min').val(ui.values[0]);
            $box.find('#amount-max').val(ui.values[1]);
        }
    });
}
;

// obecnie nieużywane, służyło do dopasowania filmu do ekranu
// function scaleToFill() {
//     $('.titleBackVid').css('visibility', 'hidden');
//     $('.titleBackVid').css('display', 'none');
//     var $container = $('div.page-header'),
//         containerWidth = $container.width(),
//         containerHeight = $container.height(),
//         tagRatio = containerWidth / containerHeight;
//     $video = {};
//
//     if (containerWidth < 600) {
//         $video = $('#smallVid');
//     } else if (containerWidth >= 800) {
//         $video = $('#bigVid');
//     } else {
//         $video = $('#midVid');
//     }
//     $video.css('display', 'block');
//     var videoRatio = $video.width() / $video.height();
//
//     if (videoRatio < tagRatio) {
//         $video.css('-webkit-transform', 'scaleX(' + tagRatio / videoRatio + ')');
//     } else if (tagRatio < videoRatio) {
//         $video.css('-webkit-transform', 'scaleY(' + videoRatio / tagRatio + ')');
//     }
//     $video.css('width', containerWidth);
//     $video.css('height', containerHeight);
//     $video.css('visibility', 'visible');
// }